<template>
<li>
    <item-card :url="`/stock/stock_checks/${item.id}`" contentClass="grid grid-cols-12" :className="item.is_accepted ? 'border-green-400' : ''">

        <template v-slot:content>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                <ItemText title="Datums" :text="item.check_date" />
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
                <ItemText title="Tips">
                    <template v-slot:content>
                        <Badge :text="item.type.name" :small="true" :className="`${statusColor(item.type.id)} mr-2 mb-2 md:mb-0`" />
                    </template>
                </ItemText>
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-4">
                <ItemText title="Noliktava" :text="item.warehouse" />
            </div>

            <template v-if="item.errors">
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <div class="flex h-full items-end">
                        <ItemText>
                            <template v-slot:content>
                                <Badge text="Ir kļūdas" :small="true" className="bg-red-400 text-red-900 mr-2 mb-2 md:mb-0" />
                            </template>
                        </ItemText>
                    </div>
                </div>
            </template>

        </template>

    </item-card>
</li>
</template>

<script>
import {mapGetters} from "vuex"
import Colors from "@/services/Helpers/colors";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
    name: "SuppliersListItem",
    components: {
        ItemCard,
        ItemText,
        Badge,
    },
    computed: {
        ...mapGetters({
        })
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    methods: {
        statusColor(id) {
            return Colors.statusColor(id)
        },
    },
}
</script>

<style>

</style>